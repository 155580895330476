import {Box, Card, Typography, useTheme, IconButton} from "@mui/material"
import {CheckBoxOutlineBlankOutlined, CheckBoxOutlined} from "@mui/icons-material"
import useIsMacOS from "../../../hooks/useIsMacOS"

/**
 * Represents the UserNoteRestrictions for a top-level note in the current workspace.
 *
 * @param note - target Note
 * @param selectedNotes - React State value declared in ManageWorkspaceMembers
 * @param setSelectedNotes - React State setter function.
 * @returns {JSX.Element}
 * @constructor
 */
export default function NoteRestrictionBreadcrumb({note, selectedNotes, setSelectedNotes}) {
	const isSelected = selectedNotes[note.id] !== undefined
	const isEdit = isSelected && selectedNotes[note.id] === "edit"
	const theme = useTheme()
	let read = isSelected
	let edit = isSelected && isEdit
	const setRead = () => {
		setSelectedNotes(prevNotes => {
			const currentPermission = prevNotes[note.id]

			// Toggle logic: if not "read", set to "read"; if "read", remove from selection
			if (currentPermission !== "read" && currentPermission !== "edit") {
				return { ...prevNotes, [note.id]: "read" }
			} else {
				// just throwing away the target note.id here
				const { [note.id]: _, ...newNotes } = prevNotes // eslint-disable-line
				return newNotes
			}
		})
	}

	const setEdit = () => {
		setSelectedNotes(prevNotes => {
			const currentPermission = prevNotes[note.id]

			if (currentPermission === "edit") {
				// Downgrade to "read" if applicable, otherwise remove permission (a note cannot have just the "edit" permission)
				return read
					? { ...prevNotes, [note.id]: "read" }
					: (({ [note.id]: _, ...newNotes }) => newNotes)(prevNotes) // eslint-disable-line
			}

			// Default to "edit" for other permission states
			return { ...prevNotes, [note.id]: "edit" }
		})
	}
	const toggleSelected = () => {
		setSelectedNotes(prevNotes => {
			if(isSelected) {
				delete prevNotes[note.id]
				return {...prevNotes}
			} else {
				return {...prevNotes, [note.id]: "edit"}
			}
		})
	}

	return (
		<Box sx={{display: "flex", gap: "1rem", alignItems: "center", width: "100%", justifyContent: "flex-start"}}>
			<Box sx={{display: "flex", width: "100%"}}>
				<Card
					onClick={toggleSelected}
					variant={"note"}
					sx={{
						width: "100%",
						display: "flex",
						alignItems: "center",
						padding: "0.5rem",
						color: "RBOneFont.main",
						backgroundColor: "notes.crumbsBackground",
						borderLeft: "5px solid",
						borderColor: "notes.dark",
						lineHeight: "1rem",
						fontSize: "1rem",
						...((read || edit) && {
							borderColor: `${theme.palette.primary.main}`,
						}),
					}}
				>
					<Typography
						variant={"body1"}
						sx={{
							marginBottom: 0,
							wordBreak: "break-word",
							fontSize: "1rem",
						}}
						paragraph
					>{note.content}</Typography>
				</Card>
				<PermissionsCheckBox isSelected={read} setIsSelected={setRead}/>
				<PermissionsCheckBox isSelected={edit} setIsSelected={setEdit}/>
			</Box>
		</Box>
	)
}

function PermissionsCheckBox({isSelected, setIsSelected}) {
	const isMacOS = useIsMacOS()
	return (
		<IconButton
			sx={{
				minWidth: isMacOS ? "38px" : "24px",
				height: "unset"
			}}
			onClick={() => setIsSelected(!isSelected)}
		>
			{ isSelected ?
				<CheckBoxOutlined fontSize={"small"}/>
				:
				<CheckBoxOutlineBlankOutlined fontSize={"small"} />
			}
		</IconButton>
	)
}