import {useDispatch, useSelector} from "react-redux"
import {selectAutomatedSearchSortBy} from "../../../reducers/automatedSearchSortBySlice"
import {setAutomatedSearchPanelNav} from "../../../reducers/automatedSearchNavSlice"
import {AUTOMATED_SEARCH_PANEL_VIEW, initializePageCount, selectAutomatedSearchSelectedSearch, selectAutoSearchListScrollPosition, setAutomatedSearchPanelView, setAutoSearchListPosition, setSelectedSearch} from "../../../reducers/automatedSearchViewSlice"
import {Box, Button, Card, CardContent, List, ListItem, Stack, Tooltip, Typography} from "@mui/material"
import {sortAutomatedSearches} from "../../../utils/automatedSearchUtilities"
import {AutomatedSearchOptions} from "./AutomatedSearchOptions"
import { formatDateToISO } from "../../../utils/dates"
import React, { useRef, useEffect } from "react"
import { debounce } from "lodash"
import AutomatedSearchCounts from "./AutomatedSearchTotalCount"

const AutomatedSearchList = React.memo( function AutomatedSearchList({
	searches,
	workspaceId,
	getSearches,
	setSearchToUpdate,
}){
	console.log("Attach Link - AUTO SEARCH LIST")
	const dispatch = useDispatch()
	const sortBy = useSelector(selectAutomatedSearchSortBy)
	const listRef = useRef(null) 
	const scrollPosition = useSelector(selectAutoSearchListScrollPosition)
	const selectedSearch = useSelector(selectAutomatedSearchSelectedSearch)
	// update scroll position every 200ms
	const updateScrollPosition = debounce((position) => {
		dispatch(setAutoSearchListPosition(position))
	}, 200)

	// initialize scroll listener debounce to keep the auto search list position tracked
	useEffect(() => {
		const list = listRef.current
		if (list) {
			const handleScroll = () => {
				const position = list.scrollTop
				updateScrollPosition(position)
			}
			
			list.addEventListener("scroll", handleScroll)
		
			// Cleanup function to remove the event listener
			return () => list.removeEventListener("scroll", handleScroll)
		}
	},[])
	
	// update the view scroll on component load
	useEffect(() => {
		if (listRef.current && scrollPosition !== undefined) {
			listRef.current.scrollTop = scrollPosition
		}
	})

	const selectSearch = (search) => {
		dispatch(initializePageCount({selectedSearch: search}))
		dispatch(setSelectedSearch(search))
		dispatch(setAutomatedSearchPanelNav(search))
		dispatch(
			setAutomatedSearchPanelView(AUTOMATED_SEARCH_PANEL_VIEW.ARTICLE_LIST)
		)
	}
	const handleClick = (evt, search) => {
		evt.stopPropagation()
		selectSearch(search)
	}


	return (
		<List sx={{ padding: "8px 0px 0px 0px", maxHeight: "100%", overflowY: "scroll" }}  ref={listRef}>
			{ sortAutomatedSearches(searches, sortBy).map((search) => {
				const backgroundStyle = selectedSearch?.id == search?.id ? {
					backgroundColor: "rgb(0, 179, 197, 0.2)"
				} : {}
				return (
					<ListItem key={search.id} sx={{ padding: "2px 4px" }} id={`search-item-${search.id}`}>
						<Card
							elevation={0}
							sx={{ width: "100%", padding: 0, ...backgroundStyle}}
							variant={"note"}
						>
							<CardContent
								sx={{
									display: "flex",
									flexDirection: "row",
									padding: 0,
									"&:last-child": { padding: 0 },
								}}
							>
								<Box
									sx={{
										flex: "1 1 100%",
										display: "flex",
										flexDirection: "column",
										flexWrap: "wrap",
										justifyContent: "center",
										alignItems: "start",
										padding: "4px 8px",
										minWidth: "300px",
									}}
								>
									<Typography
										variant={"articleTitle"}
										sx={{
											color: "RBOneFont.main",
											width: "100%",
											whiteSpace: "pre-wrap",
										}}
									>
										{search.title}
									</Typography>
									<AutomatedSearchCounts search={search} />
									<Typography
										variant={"subtitle2"}
										sx={{ color: "RBOneFont.otherInfo", width: "100%", marginY: "2px" }}>
										Created At: {formatDateToISO(search.createdAt)}
									</Typography>
								</Box>
								<Stack
									sx={{ display: "inline-flex" }}
									onClick={(e) => {
										e.stopPropagation()
									}}
								>
									<AutomatedSearchOptions
										search={search}
										workspaceId={workspaceId}
										getSearches={getSearches}
										setSearchToUpdate={setSearchToUpdate}
									/>
								</Stack>
							</CardContent>
							<Tooltip
								title={"Open automated search"}
								followCursor
								placement={"right-start"}
							>
								<Button
									onClick={(evt) => handleClick(evt, search)}
									variant={"text"}
									discreteOpen
									color={"otherInfo"}
									sx={{
										marginBottom: "2px",
									}}
								>Open Search</Button>
							</Tooltip>
						</Card>
					</ListItem>

				)

			})}
		</List>
	)
})

export default AutomatedSearchList